<template>
  <div>
    <h3>眼压(*参考范围：10-21mmHg)</h3>
    <div class="boxs">
      <div ref="intraocularPressure">
        <div class="nr">
          <div class="title">OD</div>
          <el-input   class="inp" @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_IP_OD, 5, 50)?'cw':'', srval==='CL_RE_IP_OD'?'xz':'']" @focus="getgb(postData.CL_RE_IP_OD, 'CL_RE_IP_OD', 0)" v-model="postData.CL_RE_IP_OD" placeholder="请输入内容"></el-input>
          <p>mmHg</p>
        </div>
        <div class="nr">
          <div class="title">OS</div>
          <el-input   class="inp" @keydown.native="keyd($event)" :class="[srjc(postData.CL_RE_IP_OS, 5, 50)?'cw':'', srval==='CL_RE_IP_OS'?'xz':'']" @focus="getgb(postData.CL_RE_IP_OS, 'CL_RE_IP_OS', 0)" v-model="postData.CL_RE_IP_OS" placeholder="请输入内容"></el-input>
          <p>mmHg</p>
        </div>
      </div>
      <el-button style="height: 45px;margin-left: 0.7rem;" type="primary" @click="showdrsb=true">导入设备数据</el-button>
    </div>

    <div class="upwj">
      <el-upload
          action="#"
          list-type="picture-card"
          style="display: flex"
          :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{file}">
          <img
              class="el-upload-list__item-thumbnail"
              :src="file.url" alt=""
          >
          <span class="el-upload-list__item-actions">
        <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>

    <div class="db">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.CL_RE_IP_Remark">
      </el-input>
    </div>

    <el-dialog
        title=""
        :visible.sync="showdrsb"
        width="70%"
    >
      <ImportEquipment @getdata="drdata" :types="'ct'"></ImportEquipment>
      <span slot="footer" class="dialog-footer">
         <el-button @click="showdrsb = false">取 消</el-button>
       </span>
    </el-dialog>

    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
import da from "element-ui/src/locale/lang/da";
import specialSurveys from '@/publicFn/specialSurveys'
import pf from "@/publicFn/baseFn.js"
export default {
  name: "intraocularPressure",
  data() {
    return {
      showdrsb:false,
      input: '',
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      textarea2: '',
      postData: {},
      ids: 5,
      chooseid: '',

      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,

    }
  },
  props: {
    idx: {
      default: -1
    },
    type: {
      default: 0
    }
  },
  watch: {
    idx: function (n, o) {
      if (n == this.ids && this.chooseid != this.$store.state.physicianVisits.xzConsulting) {
        if (this.$store.state.physicianVisits.xzConsulting) {
          this._api.eyeTest.getEyeYa()
              .then(res => {
                if (res.GetListResult) {
                  this.$store.commit('eyeTest/upEyeYaTest', this._clJson(res.GetListResult[0].S_OP_Json))
                  this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
                }
                this.chooseid = this.$store.state.physicianVisits.xzConsulting
                this.$store.commit('eyeTest/upEyeYaTestCid', this.chooseid)
              })
        }
      }
    },
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upEyeYaTest', n)
      },
      deep: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    this.postData = JSON.parse(JSON.stringify(this.$store.state.eyeTest.eyeYaTestData))
    if (this.type) {
      this._api.eyeTest.getEyeYa()
          .then(res => {
            if (res.GetListResult) {
              this.$store.commit('eyeTest/upEyeYaTest', this._clJson(res.GetListResult[0].S_OP_Json))
              this.postData = this._clJson(res.GetListResult[0].S_OP_Json)
            }
          })
    }
  },
  methods: {
    handleRemove(file) {
      // console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      // console.log(file);
    },

    drdata(data) {
      // console.log(data)
      if (data) {
        this.postData.CL_RE_IP_OD = data.RightCT
        this.postData.CL_RE_IP_OS = data.LeftCT
        this.showdrsb = false
      }
    },

    zh(key) {
      this.postData[key] = this.postData[key] !== ''?this.postData[key]:''
    },

    save() {
      let arr = this.$refs.intraocularPressure.getElementsByClassName('cw')
      //console.log(arr)
      if (arr.length > 0) {
        this.$confirm('请复核红色区域数据是否异常！', '提示', {
          confirmButtonText: '继续提交',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(res => {
          specialSurveys.saveEyeYa()
        })
      }else {
        specialSurveys.saveEyeYa()
      }

    },



    keyd(e) {
      // e.returnValue= false
      if (pf.isMobile()) {
        e.returnValue= false
      }
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      if (pf.isMobile()) {
        document.activeElement.blur();
      }
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData, this.srval, e)
      if (this.postData[this.srval] && !isNaN(Number(this.postData[this.srval])) && this.iszf) {
        if (this.postData[this.srval] && Number(this.postData[this.srval]) > 0 && this.postData[this.srval].indexOf('+') < 0  && this.postData[this.srval].indexOf('-') < 0) {
          this.$set(this.postData, this.srval, '+'+e)
        }
      }
    }
  },

  components: {
    ImportEquipment: () => import('@/components/specialSurveys/refractive/importEquipment'),
    KEYS: () => import('@/components/keyboard/keyHome')
  },

  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
}
</script>

<style scoped lang="scss">
  .boxs {
    width: 78vw;
    padding: 0 4vw;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .nr {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 0.2rem;
    .inp {
      width: 2rem;
      margin: 0 0.2rem;
    }
    ::v-deep .inp input:hover {background: #e8e6e6}
    .title {width: 2em;}
  }
  .upwj {
    width: 80vw;
    padding: 0.2rem 4vw;
  }
  .db {
    width: 80vw;
    padding: 0.2rem 4vw;
    text-align: left;
    p {padding-bottom: 0.1rem;}
  }
  ::v-deep .cw .el-input__inner{background: #ef4674 !important;}
  ::v-deep .xz input{background: #e8e6e6}
</style>
